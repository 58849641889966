import styles from "./styles.module.css"

export default function Banner({setModalHidden}) {
	return (
		<section className={styles.banner}>
			<div className={styles.image}>
				<img src={"/banner.jpg"} alt='баннер' width={700} height={500} />
			</div>
			<div className={styles.content}>
				<div className={styles.title}>Приглашаем Вас на презентацию!</div>
				<div className={styles.subtitle}>
					20 июля
					<br />
					суббота
					<br />
					начало в 11:45
				</div>
				<div className={styles.subtitle}>ДЦ Максимум Руставели</div>
				<div className={styles.footer}>
					Chery Tiggo 7 PRO MAX, семейный кроссовер No1* в России, обновился! Чтобы поделиться этой новостью со всеми нашими друзьями и клиентами, мы
					проводим презентацию в дилерском центре Chery Максимум Руставели!
				</div>
				<div className={styles.footer}>Специально для Вас мы подготовили:</div>
				<ul>
					<li className={styles.line}>Уникальные предложения на покупку нового Chery Tiggo 7 PRO MAX</li>
					<li className={styles.line}>Вкуснейший фуршет, коктейльный бар</li>
					<li className={styles.line}>Тест-драйвы на всех автомобилях модельного ряда CHERY</li>
					<li className={styles.line}>Памятные подарки и сертификаты на сервис для гостей</li>
					<li className={styles.line}>Мастер-классы для взрослых и детей, детская анимация и конкурсы</li>
				</ul>
				<button className={styles.button} onClick={() => setModalHidden(false)}>
					Получить приглашение
				</button>
			</div>
		</section>
	)
}
